<template>
  <div>
    <loading
      :active.sync="isLoading"
      background-color="#332abb"
      :opacity="0.99"
      color="#fff"
      :is-full-page="fullPage"
    ></loading>

    <ModalBagisYap ref="modalBagisYap"></ModalBagisYap>
    <modalVideo ref="modalVideo" :videoUrl="newData.video"></modalVideo>
    <donorsModal ref="donorsModal" :data="donorList"></donorsModal>
    <MainBanner v-if="this.infographic"
      @clicked="showModal"
      :SmallTitle="this.infographic.desc"
      :Title="this.infographic.student.toLocaleString('en')"
      :countCity="this.infographic.city"
      :countTeacher="this.infographic.teacher"
      :countSchool="this.infographic.school"
      :countStudent="this.infographic.student"
      Desc=""
      ButtonText="Bağış Yap"
      :MultipleImage="true"
      TextPosition="between"
      Height="full"
    />

    <div class="main-continer pt-0">
      <section
        class="section-padding container section-high h-max"
        id="bireysel-destekciler"
      >
        <div class="floating-carousel-info text-center">
          <h4>Bireysel Destekçiler</h4>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            <br />
            vel aliquam urna. Nam lectus leo, eleifend quis urna at, molestie
            <br />
            pharetra purus. Fusce eget mauris et lorem euismod eleifend id a
            augue.
          </p> -->
        </div>

        <div v-if="induvidualSupporters.length > 0" class="image-out">
          <div
            class="
              swiper-carousel swiper-destekciler swiper-small
              container
              position-relative
            "
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide text-center"
                v-for="(sup, index) in induvidualSupporters"
                :key="index"
              >
                <!-- <img class="img-wrapper" :src="sup.image"> -->
                <picture>
                  <source :srcset="sup.image" type="image/jpeg" />
                  <img :src="sup.image" alt="Görsel" />
                </picture>
                <h3 class="name">{{ sup.name_surname }}</h3>
                <p class="title">{{ sup.title }}</p>
              </div>
            </div>

            <div class="swiper-button swiper-button-destekciler swiper-button-next d-none d-md-flex"></div>
          <div class="swiper-button swiper-button-destekciler swiper-button-prev d-none d-md-flex"></div> 
             <div class="swiper-pagination"></div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <ButtonRounded
            class="ml-auto mr-auto mt-5"
            Text="Bağış Yap"
            :isRouting="false"
            color="blue"
            @click.native="showModal"
          />
          <ButtonRounded
            class="mt-5 ml-3"
            Text="Tüm Bağışçıları Gör"
            :isRouting="false"
            @click.native="showDonorsModal"
            color="blue"
          />
        </div>
      </section>

      <section
        class="basinda-bilim gray-section section-padding section-high h-max"
        id="kurumsal-destekciler"
      >
        <div class="container text-center">
          <h4 class="mb-3">Kurumsal Destekçiler</h4>
     
        </div>

        <div class="container">
          <div
            class="
              swiper-carousel swiper-basinda-bilimseferberligi swiper-small
              position-relative
              pt-5
            "
            v-if="coorporateSupporters.length > 0"
          >
            <div class="swiper-wrapper">
              <div class="swiper-slide text-center" v-for="(corp, index) in coorporateSupporters" :key="index">
                <div class="logo-wrapper">
                  <img :src="corp.image" />
                </div>
                <h3 class="name">{{ corp.name_surname }}</h3>
              </div>
            </div>

            <div class="swiper-button swiper-button-destekciler swiper-button-next d-none d-md-flex"></div>
              <div class="swiper-button swiper-button-destekciler swiper-button-prev d-none d-md-flex"></div>
            <!-- <div class="swiper-pagination"></div> -->
          </div>
          <ButtonRounded
            class="ml-auto mr-auto mt-5"
            Text="Bağış Yap"
            :isRouting="false"
            color="blue"
            @click.native="showModal"
          />
          <div class="w-100 text-center">
            <p class="mt-5">Sizlerde kurumunuz ile bir iş birliği modeli hayal ediyorsanız <a href="mailto:info@bilimseferberligi.org" >info@bilimseferberligi.org</a> adresinden bizimle iletişime geçebilirsiniz.</p>
          </div>
        </div>
      </section>
      <section class="section-padding">
        <div class="floating-carousel-info text-center">
          <h4>Destekleyenler</h4>
   
        </div>

        <div
          class="swiper-carousel swiper-small swiper-destekciler container position-relative"
          v-if="supportsVideo.length > 0"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide play-video text-center"
              @click="showModalVideo(sup)"
              v-for="(sup, index) in supportsVideo"
              :key="index"
            >
              <span class="svg-icon icon-video"></span>
              <img :src="sup.image" />
              <h3 class="name mt-2">{{ sup.title }}</h3>
              <p class="title small">{{ sup.desc }}</p>
            </div>
          </div>
          <div class="swiper-button swiper-button-destekciler swiper-button-next d-none d-md-flex"></div>
              <div class="swiper-button swiper-button-destekciler swiper-button-prev d-none d-md-flex"></div>
          <div class="f swiper-pagination  "></div>
        </div>
      </section>

      <Quotes />

      <div class="spacer clearfix"></div>

      <FooterLanding />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var VueScrollTo = require("vue-scrollto");
import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";
import ButtonRounded from "@/components/ButtonRounded";
import ModalBagisYap from "@/components/ModalBagisYap";
import donorsModal from "@/components/donorsModal";
import Quotes from "@/components/Quotes";
import ModalVideo from "@/components/ModalVideo";
export default {
  name: "Destekçilerimiz",
  components: {
    MainBanner,
    FooterLanding,
    ModalBagisYap,
    Quotes,
    ButtonRounded,
    Loading,
    ModalVideo,
    donorsModal,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      bagisSecTitle: "Ben Nasıl Destek Olabilirim?",
      induvidualSupporters: {},
      coorporateSupporters: {},
      mainSupporters: {},
      supportsVideo: {},
      newData: {},
      supps: {},
      donorList: "",
      bagisSecDesc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam facilisis ornare arcu eget iaculis. <br> Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      infographic: '',
    };
  },
  watch: {
    $route(to, from) {
      // this._swiperInit();
    },
  },
  mounted() {
    function scrollToAnchor(url) {
      var urlHash = url.split("#")[1];
      if (urlHash && $("#" + urlHash).length) {
        $("html").animate(
          {
            scrollTop: $("#" + urlHash).offset().top - 60,
          },
          500
        );
      }
    }

    $(document).ready(function () {
      setTimeout(() => {
        scrollToAnchor(window.location.href);
      }, 300);
    });
    this.getSupporters();
    this.getSupports();
    this._swiperInit();
    this.getDonors();
    this.getInfographic();
    // this._swiperInit();
  },
  metaInfo() {
    return {
      title: "Destekçilerimiz - Gelin, Bilim Seferberliği'ne siz de katılın. ",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Destekleyin, çocuklarımız bilimle büyüsün, ülkemizi büyütsün.",
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: "Destekçilerimiz - Gelin, Bilim Seferberliği'ne siz de katılın. " },
        { property: "og:description", content: 'Destekleyin, çocuklarımız bilimle büyüsün, ülkemizi büyütsün.' },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content:  "Destekçilerimiz - Gelin, Bilim Seferberliği'ne siz de katılın. " },
        { name: "twitter:description", content: 'Destekleyin, çocuklarımız bilimle büyüsün, ülkemizi büyütsün.' },
        { name: "twitter:url", content: window.location.href},
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  methods: {
    getInfographic(){
      this.$api.getInfographic().then((response) => {
        this.infographic = response;
      });
    },
    getDonors() {
      this.$api.getDonors().then((response) => {
        this.donorList = response;
      });
    },
    showModalVideo(data) {
      this.$refs.modalVideo.openModal(data);
      this.newData = data;
    },
    showDonorsModal() {
      this.$refs.donorsModal.openModal();
    },
    getSupporters() {
      this.$api.getSupporters().then((response) => {
        this.supps = response;
        this.induvidualSupporters = response.support_individual;
        this.coorporateSupporters = response.support_corporate;
        this.mainSupporters = response.advisory_board;

        setTimeout(() => {
          this._swiperInit();
        }, 300);
      });
    },
    getSupports() {
      this.$api.getSupports().then((response) => {
        this.supportsVideo = response;
      });
    },
    showModal() {
      this.$refs.modalBagisYap.openModal();
      this.$refs.modalBagisYap.getSwiper();
    },
    _swiperInit() {
      const swiperBilimSenlikleri = new Swiper(".swiper-destekciler", {
        spaceBetween: 20,
        //loop: false,
        // slidesPerView:5,
        breakpoints: {
          567: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 4,
          },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
    //     pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
        
      });

      const swiperBasinda = new Swiper(".swiper-basinda-bilimseferberligi", {
        loop: false,
        //slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        centeredSlides: false,
      
      });
    },
  },
};
</script>
<style lang="scss">
.play-video{
  cursor: pointer;
}
.swiper-destekciler {
  .name {
    margin-top: 1rem;
    font-size: 1.175rem;
  }
  img {
    width: 100%;
  }
  .svg-icon {
    background: var(--purpleLight);
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
@media (max-width: 567px) {
  .floating-carousel-info {
    width: 100%;
    max-width: 100%;
  }
  .image-out {
    max-width: 400px;
  }
}
.button-rounded {
  font-size: 0.9rem;
}
.swiper-pagination {
  width: 100%;
  position: relative !important;
  padding-bottom: 30px;
  .swiper-pagination-bullet {
    margin-left: 5px;
  }
}
.basinda-bilim {
  .container {
    h4 {
      font-size: 1.375rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }
}
.swiper-destekciler {
  h3 {
    margin-top: 1rem;
  }
}
.h-max {
  height: max-content !important;
}
</style>